import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DictionaryGuard } from '@teamfoster/dictionary-ngrx';
import { NotFoundComponent } from './404.component';
import { BeaconToursGuard } from './game/guards';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    pathMatch: 'full',
    canActivate: [DictionaryGuard, BeaconToursGuard],
  },
  {
    path: ':beaconTourId',
    loadChildren: () => import('./game/game.module').then(m => m.GameModule),
    canActivate: [DictionaryGuard, BeaconToursGuard],
  },
  {
    path: 'styleguide',
    loadChildren: () => import('./style-guide/style-guide.module').then(m => m.StyleGuideModule),
  },
  {
    path: '404',
    component: NotFoundComponent,
    // canActivate: [DictionaryGuard],
  },
  {
    path: '**',
    redirectTo: '/404',
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
