import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromBeaconTours from '../reducers/beacon-tours.reducer';
import { BeaconTour } from '../../models';
import * as fromRoot from '../../../store';

export const getBeaconTourState = createSelector(fromFeature.getCircleState, (state: fromFeature.GameState) => state.beaconTours);

export const getBeaconToursEntities = createSelector(getBeaconTourState, fromBeaconTours.getBeaconToursEntities);
export const getBeaconToursLoaded = createSelector(getBeaconTourState, fromBeaconTours.getBeaconToursLoaded);
export const getBeaconToursLoading = createSelector(getBeaconTourState, fromBeaconTours.getBeaconToursLoading);
export const getBeaconToursPreloaded = createSelector(getBeaconTourState, fromBeaconTours.getBeaconTourPreloaded);
export const getBeaconToursPreloading = createSelector(getBeaconTourState, fromBeaconTours.getBeaconTourPreloading);

export const getAllBeaconTours = createSelector(getBeaconToursEntities, entities => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getSelectedBeaconTour = createSelector(getBeaconToursEntities, fromRoot.getRouterState, (entities, router): BeaconTour => {
  return router?.state && entities[router.state.params?.beaconTourId || router.state.data?.beaconTourId];
});
