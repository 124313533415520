import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Html5PlayerVars } from '@teamfoster/ui-components';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  @Input() imageUrl: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() cssClass: string;
  @Input() videoBg: string;
  @Input() playerVars: Html5PlayerVars = {
    autoplay: true,
    controls: false,
    muted: true,
    loop: true,
  };
  @Output() inView = new EventEmitter<any>();
  inviewPort = false;
  
  constructor() {}

  ngOnInit(): void {}

  inViewChange(e: boolean) {
    this.inviewPort = e;
    if (e) {
      this.inView.emit(null);
    }
  }
}
