import { trigger, transition, style, query as q, group, animate } from '@angular/animations';

// tslint:disable-next-line: no-shadowed-variable
const query = (style, animate, optional = { optional: true }) => q(style, animate, optional);

export const routerFade = trigger('routerFade', [
  transition('* <=> *', [
    query(':enter, :leave', style({ position: 'fixed', width: '100%', height: '100%' })),
    group([
      query(':enter', [
        style({ opacity: 0 }),
        animate('0.3s ease-out', style({ opacity: 1 })),
      ]),
      query(':leave', [animate('0.1s ease-out', style({ opacity: 0 }))]),
    ]),
  ])
]);
