import { BeaconTour, BeaconTourPhase, WaypointPhase } from '../../models';
import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/game.action';
import * as utility from '../../../utility';

export interface GameState {
  waypointIndex: number;
  beaconTourPhase: BeaconTourPhase;
  waypointPhase: WaypointPhase;
  beaconTourId: number;
  points: number;
  pointsEarned: number;
  quizAttempts: number;
  hadHelp: boolean;
  gamePhase: number;
}

export const initialState: GameState = {
  waypointIndex: 0,
  beaconTourPhase: BeaconTourPhase.STARTUP,
  waypointPhase: WaypointPhase.NAVIGATION,
  beaconTourId: 0,
  points: 100,
  pointsEarned: 0,
  quizAttempts: 0,
  hadHelp: false,
  gamePhase: 0,
};

export const GameReducer = createReducer(
  initialState,
  on(actions.SetGamePhase, (state: GameState, { phase }) => {
    return {
      ...state,
      gamePhase: phase,
    };
  }),
  on(actions.SetWaypointPhase, (state: GameState, { phase }) => {
    return {
      ...state,
      waypointPhase: phase,
    };
  }),
  on(actions.SetBeaconTourPhase, (state: GameState, { phase }) => {
    return {
      ...state,
      beaconTourPhase: phase,
    };
  }),
  on(actions.SetWaypointIndex, (state: GameState, { index }) => {
    return {
      ...state,
      waypointIndex: index,
      waypointPhase: WaypointPhase.NAVIGATION,
    };
  }),
  on(actions.NextWaypoint, (state: GameState) => {
    return {
      ...state,
      waypointIndex: state.waypointIndex + 1,
      waypointPhase: WaypointPhase.NAVIGATION,
      pointsEarned: 0,
      quizAttempts: 0,
      hadHelp: false,
    };
  }),
  on(actions.AddPoints, (state: GameState, { points, attempts }) => {
    return {
      ...state,
      points: state.points + points,
      pointsEarned: points,
      quizAttempts: attempts,
    };
  }),
  on(actions.SetHadHelp, (state: GameState) => {
    return {
      ...state,
      hadHelp: true,
    };
  })
);

export const getWaypointIndex = (state: GameState) => state.waypointIndex;
export const getWaypointPhase = (state: GameState) => state.waypointPhase;
export const getGamePhase = (state: GameState) => state.gamePhase;
export const getBeaconTourPhase = (state: GameState) => state.beaconTourPhase;
export const getPoints = (state: GameState) => state.points;
export const getPointsEarned = (state: GameState) => state.pointsEarned;
export const getQuizAttempts = (state: GameState) => state.quizAttempts;
export const getHadHelp = (state: GameState) => state.hadHelp;
