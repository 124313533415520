import { BeaconTour } from '../../models';
import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/beacon-tours.action';
import * as utility from '../../../utility';

export interface BeaconToursState {
  entities: { [id: number]: BeaconTour };
  loading: boolean;
  loaded: boolean;
  error?: any;
  preloading?: number;
  preloaded?: number;
}

export const initialState: BeaconToursState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const BeaconTourReducer = createReducer(
  initialState,
  on(actions.loadBeaconTours, (state: BeaconToursState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(actions.loadBeaconToursSuccess, (state: BeaconToursState, { beaconTours }) => {
    const entities = utility.ToEntities(beaconTours, 'id', {});

    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(actions.loadBeaconToursFail, (state: BeaconToursState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  }),
  on(actions.preloadBeaconTour, (state: BeaconToursState, { id }) => {
    return {
      ...state,
      preloading: id,
      preloaded: null,
    };
  }),
  on(actions.preloadBeaconTourSuccess, (state: BeaconToursState, { id }) => {
    return {
      ...state,
      preloading: null,
      preloaded: id,
    };
  })
);

export const getBeaconToursEntities = (state: BeaconToursState) => state.entities;
export const getBeaconToursLoading = (state: BeaconToursState) => state.loading;
export const getBeaconToursLoaded = (state: BeaconToursState) => state.loaded;

export const getBeaconTourPreloading = (state: BeaconToursState) => state.preloading;
export const getBeaconTourPreloaded = (state: BeaconToursState) => state.preloaded;
