import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromGame from '../reducers/game.reducer';
import * as fromRoot from '../../../store';

export const getGameState = createSelector(fromFeature.getCircleState, (state: fromFeature.GameState) => state.game);

export const getWaypointIndex = createSelector(getGameState, fromGame.getWaypointIndex);
export const getWaypointPhase = createSelector(getGameState, fromGame.getWaypointPhase);
export const getGamePhase = createSelector(getGameState, fromGame.getGamePhase);
export const getBeaconTourPhase = createSelector(getGameState, fromGame.getBeaconTourPhase);
export const getPoints = createSelector(getGameState, fromGame.getPoints);
export const getPointsEarned = createSelector(getGameState, fromGame.getPointsEarned);
export const getQuizAttempts = createSelector(getGameState, fromGame.getQuizAttempts);
