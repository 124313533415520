import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { HeaderComponent } from './components/header/header.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { InViewModule } from '@teamfoster/utility';
import { PipesModule } from '@teamfoster/pipes';
import { Html5VideoPlayerModule, IconModule } from '@teamfoster/ui-components';
import { GridOverlayComponent } from './components/grid-overlay/grid-overlay.component';
import { IconComponent } from './components/icon/icon.component';
import { ImageComponent } from './components/image/image.component';

const moduleExports = [
  LoaderIndicatorComponent,
  HeaderComponent,
  TopbarComponent,
  FooterComponent,
  GridOverlayComponent,
  IconComponent,
  ImageComponent,
];

@NgModule({
  imports: [CommonModule, RouterModule, InViewModule, PipesModule, Html5VideoPlayerModule, IconModule],
  declarations: [...moduleExports],
  exports: [...moduleExports],
  providers: [],
})
export class CoreModule {}
