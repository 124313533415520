import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { StoreModule, MetaReducer, ActionReducerMap } from '@ngrx/store';
import { StoreRouterConnectingModule, RouterStateSerializer } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '../environments/environment';
import { DictionaryConfig, DictionaryNgrxModule, DictionaryGuard, LanguageService } from '@teamfoster/dictionary-ngrx';
import { IconModule } from '@teamfoster/ui-components';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { reducers, CustomSerializer, appEffects, metaReducers } from './store';

import localeNl from './nl';
import { registerLocaleData } from '@angular/common';

// not used in production
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NotFoundComponent } from './404.component';
import { HttpStatusCodeService } from './core/http-status-code.service';
import { ServiceWorkerModule } from '@angular/service-worker';

const dictConfig: DictionaryConfig = {
  mapping: [
    {
      domainPart: '.nl/',
      language: 'nl',
    },
  ],
};

registerLocaleData(localeNl, 'nl');
@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    CoreModule,
    IconModule.forRoot({ spritesheetUrl: 'assets/gfx/svg-sprite.svg' }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot(appEffects),
    StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    DictionaryNgrxModule.forRoot(dictConfig),
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [DictionaryGuard, LanguageService, { provide: LOCALE_ID, useValue: 'nl' }],
  bootstrap: [AppComponent],
})
export class AppModule {}
