import { createAction, props } from '@ngrx/store';
import { BeaconTourPhase, WaypointPhase } from '../../models';

export const SetGamePhase = createAction('[Game] Set Game Phase', props<{ phase: number }>());
export const SetBeaconTourPhase = createAction('[Game] Set Scavenger Hunt Phase', props<{ phase: BeaconTourPhase }>());
export const SetWaypointIndex = createAction('[Game] Set Active Waypoint Index', props<{ index: number }>());
export const SetWaypointPhase = createAction('[Game] Set Waypoint Phase', props<{ phase: WaypointPhase }>());

export const NextWaypoint = createAction('[Game] Activate next waypoint');

export const AddPoints = createAction('[Game] Add points', props<{ points: number; attempts: number }>());
export const SetHadHelp = createAction('[Game] Set had help');
