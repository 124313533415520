import { Component, OnInit, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';

import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { Router, NavigationEnd, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { DictionaryState } from '@teamfoster/dictionary-ngrx';
import { Observable, BehaviorSubject, timer, of } from 'rxjs';
import { ContentPage } from './content/models';

import * as navActions from './store/actions/nav.action';
import * as navSelectors from './store/selectors/nav.selector';
import { tap, take } from 'rxjs/operators';
import { routerFade } from './animations';
import { ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [routerFade],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  loading$ = new BehaviorSubject<boolean>(true);
  dictionaryLoaded$: Observable<boolean>;
  menuItems$: Observable<ContentPage[]>;
  footerItems$: Observable<ContentPage[]>;
  menuOpen$: Observable<boolean>;

  testGridEnabled = false;
  routerAnimationState = '';

  private previousPath = '';

  constructor(
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId,
    private router: Router,
    private store: Store<DictionaryState>,
    private renderer: Renderer2
  ) {
    this.dictionaryLoaded$ = of(true); // this.store.select(getDictionaryLoaded);
    this.menuOpen$ = this.store.select(navSelectors.getMenuOpen).pipe(tap(a => this.updateRoot(a)));

    // this.menuItems$ = this.store.select(selectors.getMenuPages);
    // this.footerItems$ = this.store.select(selectors.getFooterPages);

    this.router.events.subscribe(event => {
      if (isPlatformBrowser(this.platformId) && event instanceof NavigationEnd) {
        (<any>window).gtag('config', 'UA-XXXXXXXXX-X', { page_path: event.urlAfterRedirects, anonymize_ip: true });
      }
    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.document.querySelector('body').classList.add('set--in');

      timer(200)
        .pipe(
          take(1),
          tap(() => this.loading$.next(false))
        )
        .subscribe();
    } else {
      this.loading$.next(false);
    }
  }

  toggleMenu() {
    this.store.dispatch(navActions.ToggleMenu());
  }

  closeMenu() {
    this.store.dispatch(navActions.CloseMenu());
  }

  openMenu() {
    this.store.dispatch(navActions.OpenMenu());
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData;
  }

  private updateRoot(menuActive) {
    menuActive
      ? this.renderer.addClass(this.document.body, 'main-nav--active')
      : this.renderer.removeClass(this.document.body, 'main-nav--active');
  }
}
