<div class="topbar" [class.set--fixed]="fixed">
  <div class="container">
    <h1 class="topbar__logo">
      <a [routerLink]="'/'">
        <div class="topbar__logo__title">Titel</div>
      </a>
    </h1>
    <!-- <button class="topbar__nav-toggle" [class.is--active]="menuActive" (click)="toggle.emit()">
      <span class="line"></span>
      <span class="line"></span>
      <span class="line"></span>
    </button> -->
    <nav class="topbar__nav">
      <ul class="topbar__nav__list">
        <li class="topbar__nav__list__item">
          <button (click)="toggle.emit()" [routerLink]="'/'" [routerLinkActive]="'is--current'" [routerLinkActiveOptions]="{ exact: true }">
            Home
          </button>
        </li>
        <li class="topbar__nav__list__item" *ngFor="let page of menuItems">
          <button (click)="toggle.emit()" [routerLink]="page.url" [routerLinkActive]="'is--current'">{{ page.menuTitle || page.title }}</button>
        </li>
      </ul>
    </nav>
  </div>
</div>
