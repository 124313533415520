import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BeaconTour } from '../models';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BeaconTourService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  loadBeaconTour(id: string) {
    return this.http.get<BeaconTour>(`${this.BASE_URL}api/beacon-tours/${id}`);
  }

  loadBeaconTours() {
    return this.http.get<BeaconTour[]>(`${this.BASE_URL}api/beacon-tours`);
  }

  preloadBeaconTour(tour: BeaconTour) {
    const audioFiles = [...tour.waypoints.map(a => a.audioUrl), ...tour.waypoints.map(a => a.backgroundAudioUrl)].filter(a => a);
    return from(Promise.all(audioFiles.map(this.preloadAudio))).pipe(map(() => tour.id));
  }

  private preloadAudio(url: string): Promise<string> {
    const audio = new Audio();
    audio.src = url;
    return new Promise((res, req) => audio.addEventListener('canplaythrough', () => res(url), false));
    // once file is loaded, the promise will be resolved
    // the file will be kept by the browser as cache
  }
}
