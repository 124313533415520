<div class="app">
  <div class="app__inner" [class.anim--in]="!(loading$ | async)">
    <!-- <app-topbar
      (toggle)="toggleMenu()"
      (close)="closeMenu()"
      (open)="openMenu()"
      [menuActive]="menuOpen$ | async"
      [menuItems]="menuItems$ | async"
      *ngIf="dictionaryLoaded$ | async"
    ></app-topbar> -->
    <div 
      class="app__router" 
      [@routerFade]="prepareRoute(outlet)"
    >
      <router-outlet #outlet="outlet"></router-outlet>
    </div>

    <!-- <app-footer></app-footer> -->
  </div>
  <app-loader-indicator [loading]="loading$ | async"></app-loader-indicator>
</div>

<app-grid-overlay *ngIf="testGridEnabled"></app-grid-overlay>