import { createSelector } from '@ngrx/store';

import * as fromBeaconTours from './beacon-tours.selector';
import * as fromGame from './game.selector';

export const getWaypoints = createSelector(fromBeaconTours.getSelectedBeaconTour, s => {
  return s && s.waypoints;
});

export const getSelectedWaypoint = createSelector(fromGame.getWaypointIndex, getWaypoints, (index, waypoints) => {
  return waypoints && { ...waypoints[index] };
});

export const getWaypointProgression = createSelector(getWaypoints, getSelectedWaypoint, (waypoints, waypoint) => {
  if (!waypoints || !waypoint) {
    return 0;
  }
  const index = waypoints.findIndex(a => a.id === waypoint.id);
  const progress = (index / waypoints.length) * 100;
  return progress;
});

export const hasNextWaypoint = createSelector(getWaypoints, fromGame.getWaypointIndex, (waypoints, index) => {
  return index < waypoints?.length;
});
