import * as fromBeaconTour from './beacon-tours.reducer';
import * as fromGame from './game.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'game';

export interface GameState {
  beaconTours: fromBeaconTour.BeaconToursState;
  game: fromGame.GameState;
}

export const reducers: ActionReducerMap<GameState> = {
  beaconTours: fromBeaconTour.BeaconTourReducer,
  game: fromGame.GameReducer,
};

export const getCircleState = createFeatureSelector<GameState>(featureKey);

export const metaReducers: MetaReducer<GameState>[] = !environment.production ? [] : [];
