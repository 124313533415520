import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-grid-overlay',
  templateUrl: './grid-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridOverlayComponent implements OnInit {
  gridColumns = [...Array(12).keys()];
  
  constructor() {}

  ngOnInit(): void {}
}
