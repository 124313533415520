import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { getSelectedBeaconTour } from '../selectors';
import { BeaconTourService } from '../../services';

import * as actions from '../actions/beacon-tours.action';

@Injectable()
export class BeaconTourEffects {
  constructor(private actions$: Actions, private service: BeaconTourService, private store: Store) {}

  loadBeaconTours$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadBeaconTours),
      switchMap(() =>
        this.service.loadBeaconTours().pipe(
          map(beaconTours => actions.loadBeaconToursSuccess({ beaconTours })),
          catchError(error => of(actions.loadBeaconToursFail({ error })))
        )
      )
    );
  });

  preloadBeaconTour$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.preloadBeaconTour),
      withLatestFrom(this.store.select(getSelectedBeaconTour)),
      switchMap(([{}, tour]) =>
        this.service.preloadBeaconTour(tour).pipe(
          map(id => actions.preloadBeaconTourSuccess({ id })),
          catchError(error => of(actions.loadBeaconToursFail({ error })))
        )
      )
    );
  });
}
